export const photoRatingMatchPath = '/match'
export const photoRatingSettingsPath = '/rating-settings'

export const countPhotosInStack = 4
export const maxVisibleNumberOfCountries = 8
export const photoRatingLimit = 30
export const minPhotoQueueLength = 6

export const limitExceededErrorCode = 'limit_exceeded'
export const vipLimitExceededErrorCode = 'vip_limit_exceeded'

export const ratingAdvancedFiltersRestricted =
  'rating_advanced_filters_restricted'

export const triggerVelocity = 0.2

export const draggableThreshold = 540

export const maxDescriptionTextLength = 300

export const smallDotClassName = 'dot-small'
export const activeDotClassName = 'dot-active'
export const numberPhotoToChangePaginationPhotoRating = 10
export const numberDotsShowCarouselPhotoRating = 10
