import { setTag } from '@sentry/core'

import { ApiResult } from 'api/fetchApi'
import { DeprecatedApi6Error } from 'api/types'
import { NodeHeaders } from 'server/NodeHeaders'

export const resolveLocale = (headers: NodeHeaders): string => {
  if ((process.env.browser as unknown) as boolean) {
    return window.__INITIAL_STATE__.systemReducer.locale
  }

  if (headers && headers['X-ApiLocale']) {
    return headers['X-ApiLocale']
  }

  return ''
}

export const hasApiError = (error: DeprecatedApi6Error | ApiResult) => {
  if ((error as ApiResult).error) {
    return true
  }

  return Boolean((error as DeprecatedApi6Error).internalError)
}

export const setSentryTags = (response: Response) => {
  const nodeName = response.headers.get('X-Mmb-Powered-By')
  const server = response.headers.get('Server')

  if (nodeName) {
    setTag('node', nodeName)
  }

  if (server) {
    /**
     * Нужно для отлова Варити:
     * https://variti.io/
     */
    setTag('server', server)
  }
}
