import React from 'react'

import loadable from '@loadable/component'

import { featuredPhotosX3PromoPath } from 'components/block/FeaturedPhotosPromoX3/FeaturedPhotosX3Notice.path'
import { GeoPermissionRequestModal } from 'components/block/GeoPermissionRequest/GeoPermissionRequestModal'
import { thenModalPath } from 'components/block/Then/Then.paths'
import { ThenPseudoModal } from 'components/block/Then/ThenPseudoModal'
import { AppRoute } from 'components/components.types'
import {
  AlertPaymentLoadable,
  alertPaymentPagePath,
} from 'components/page/Boarding/AlertPayment/AlertPaymentLoadable'
import { BlockedByIpListLoadable } from 'components/page/Boarding/BlockedModalList/BlockedByIpListLoadable'
import { BlockedByTrackLoadable } from 'components/page/Boarding/BlockedModalList/BlockedByTrackLoadable'
import { aboutCompanyPath } from 'components/page/Boarding/boarding.paths'
import { supportFormPath } from 'components/page/Boarding/SupportForm/SupportForm.paths'
import SupportFormLoadable from 'components/page/Boarding/SupportForm/SupportFormLoadable'
import { ContactEditLoadable } from 'components/page/Chat/Messenger/Contact/ContactEditLoadable'
import { restrictedPhotoWarningFullPath } from 'components/page/Chat/Messenger/RestrictedPhotoWarningModal/paths'
import { RestrictedPhotoWarningModalLoadable } from 'components/page/Chat/Messenger/RestrictedPhotoWarningModal/RestrictedPhotoWarningModalLoadable'
import { deleteContactListPath } from 'components/page/ContactList/ContactList.path'
import { DeleteContactsModalLoadable } from 'components/page/ContactList/DeleteContactsModalLoadable'
import { deleteProfileRoutes } from 'components/page/DeleteProfile/DeleteProfile.routes'
import { DiamondsModalLoadable } from 'components/page/DiamondsInfo/DiamondsModalLoadable'
import { NetworkErrorLoadable } from 'components/page/Error/NetworkErrorLoadable'
import { GetLinkForDownloadAppLoadable } from 'components/page/GetLinkForDownLoadApp/GetLinkForDownloadAppLoadable'
import { AgreementVipPage } from 'components/page/Legal'
import { AgreementLoadable } from 'components/page/Legal/Agreement/AgreementLoadable'
import { ConfidentialityLoadable } from 'components/page/Legal/Confidentiality/ConfidentialityLoadable'
import { passwordResetPath } from 'components/page/Password/PasswordReset/passwordReset.paths'
import { PasswordResetLoadable } from 'components/page/Password/PasswordReset/PasswordResetLoadable'
import {
  photoRatingMatchPath,
  photoRatingSettingsPath,
} from 'components/page/PhotoRating/constants'
import { MatchModalLoadable } from 'components/page/PhotoRating/MatchModalLoadable'
import { PhotoRatingSettingsLoadable } from 'components/page/PhotoRatingSettings/PhotoRatingSettingsLoadable'
import {
  photoViewerModalAttachPath,
  photoViewerModalPath,
} from 'components/page/PhotoViewer/PhotoViewer.paths'
import { PhotoViewerAttachLoadable } from 'components/page/PhotoViewer/PhotoViewerAttachLoadable'
import { PhotoViewerLoadable } from 'components/page/PhotoViewer/PhotoViewerLoadable'
import { privateAlbumPath } from 'components/page/PrivateAlbum/PrivateAlbum.paths'
import { ProfileCountriesVisitedEditPath } from 'components/page/ProfileCountryVisited/ProfileCountryVisited.paths'
import { ProfileCountriesVisitedEditLoadable } from 'components/page/ProfileCountryVisited/ProfileCountryVisitedLoadable'
import { profileGiftsSelfPath } from 'components/page/ProfileGiftsModal/ProfileGifts.paths'
import { ProfileGiftsModalLoadable } from 'components/page/ProfileGiftsModal/ProfileGiftsModalLoadable'
import { profileSharingPath } from 'components/page/ProfileSharing/ProfileSharing.paths'
import { ProfileSharingModalLoadable } from 'components/page/ProfileSharing/ProfileSharingModalLoadable'
import { astroStarModalRoutes } from 'components/page/PromoService/Promo/AstroStar/AstroStarModal/AstroStarModal.routes'
import { teamoModalRoutes } from 'components/page/PromoService/Promo/Teamo/TeamoModal/TeamoModal.routes'
import RestorePasswordLoadable, {
  restorePasswordPath,
} from 'components/page/RestorePassword/RestorePasswordLoadable'
import { profileSearchFullPath } from 'components/page/Search/SearchProfile/SearchProfile.paths'
import { AboutCompanyLoadable } from 'components/page/Settings/AboutCompanyLoadable'
import { locationRoutes } from 'components/page/Settings/Location/Location.routes'
import { MessengerStickerLoadable } from 'components/page/Sticker/messenger/MessengerStickerLoadable'
import { uploadPhotosPath } from 'components/page/UploaderPhotos/Uploader.paths'
import { vipBeelineSubscriptionPath } from 'components/page/VipBeeline/VipBeeline.paths'
import { VipBeelineLoadable } from 'components/page/VipBeeline/VipBeelineLoadable'
import { vipMegafonPath } from 'components/page/VipMegafon/VipMegafon.paths'
import { VipMegafonLoadable } from 'components/page/VipMegafon/VipMegafonLoadable'
import {
  downloadAppPath,
  networkErrorModalPath,
  photoRestrictionsPath,
  messengerStickerPath,
  blockedByIpReasonsPath,
  appAgreementVipAppPath,
  appAgreementIndexPath,
  messengerEditContactVendorPath,
  blockedByTrackReasonsPath,
  confidentialityPath,
  requestGeoPermissionPath,
  diamondsPath,
} from 'components/paths'
import { FoundComponent } from 'components/system/FoundComponent'
import { withLoader } from 'components/system/loadable/withLoader'
import { uniNoticeFullPath } from 'components/uninotice/UniNotice.paths'
import { UniNoticeLoadable } from 'components/uninotice/UniNoticeLoadable'

const commonModalRoutes: AppRoute[] = [
  {
    path: thenModalPath,
    component: ThenPseudoModal,
  },
  {
    path: alertPaymentPagePath,
    component: AlertPaymentLoadable,
  },
  {
    path: appAgreementIndexPath,
    component: AgreementLoadable,
  },
  {
    path: appAgreementVipAppPath,
    component: () => <AgreementVipPage fade={true} />,
  },
  { path: confidentialityPath, component: ConfidentialityLoadable },
  {
    path: '/vip',
    component: () => <AgreementVipPage fade={true} />,
  },
  {
    path: supportFormPath,
    component: SupportFormLoadable,
    exact: false,
  },

  {
    path: restorePasswordPath,
    component: RestorePasswordLoadable,
    exact: false,
  },
  {
    path: passwordResetPath,
    component: PasswordResetLoadable,
    exact: false,
    unauthorizedOnly: true,
  },
  {
    path: networkErrorModalPath,
    component: NetworkErrorLoadable,
  },
  {
    path: blockedByIpReasonsPath,
    component: BlockedByIpListLoadable,
  },
  {
    path: downloadAppPath,
    component: GetLinkForDownloadAppLoadable,
  },
  {
    path: blockedByTrackReasonsPath,
    component: BlockedByTrackLoadable,
  },
  {
    path: deleteContactListPath,
    component: DeleteContactsModalLoadable,
    authorizedOnly: true,
  },
  {
    path: uniNoticeFullPath,
    component: UniNoticeLoadable,
    exact: false,
  },
  {
    path: aboutCompanyPath,
    component: AboutCompanyLoadable,
  },
  ...deleteProfileRoutes,
  /**
   * Тоже самое что выше, ловим 404
   */
  {
    path: profileSearchFullPath,
    component: FoundComponent,
    exact: false,
  },
  {
    path: photoViewerModalAttachPath,
    component: PhotoViewerAttachLoadable,
    exact: false,
  },
  // для rating, теперь там можно открывать
  // {
  //   path: photoViewerFullPath,
  //   component: PhotoViewerLoadable,
  //   exact: false,
  // },
  {
    path: photoViewerModalPath,
    component: PhotoViewerLoadable,
    exact: false,
  },
  {
    path: privateAlbumPath,
    component: loadable(
      async () =>
        (await import('components/page/PrivateAlbum/PrivateAlbumRoutes'))
          .PrivateAlbumRoutes,
      withLoader
    ),
    authorizedOnly: true,
    exact: false,
  },
  {
    path: uploadPhotosPath,
    component: loadable(
      async () =>
        (await import('components/page/UploaderPhotos/UploaderPhotosRoutes'))
          .UploaderPhotosRoutes,
      withLoader
    ),
    authorizedOnly: true,
    exact: false,
  },
  {
    path: photoRestrictionsPath,
    component: loadable(() => import('components/page/Legal/PhotoRestriction')),
    authorizedOnly: true,
    exact: true,
  },
  {
    path: featuredPhotosX3PromoPath,
    component: loadable(() =>
      import('components/block/FeaturedPhotosPromoX3/FeaturedPhotosX3Notice')
    ),
    exact: true,
  },
  {
    path: messengerStickerPath,
    component: MessengerStickerLoadable,
    exact: false,
  },
  {
    path: messengerEditContactVendorPath,
    component: ContactEditLoadable,
    exact: false,
  },
  {
    path: photoRatingSettingsPath,
    component: PhotoRatingSettingsLoadable,
    exact: false,
  },
  {
    path: photoRatingMatchPath,
    component: MatchModalLoadable,
    exact: false,
  },
  {
    path: profileGiftsSelfPath,
    component: ProfileGiftsModalLoadable,
    exact: false,
  },
  {
    path: profileSharingPath,
    component: ProfileSharingModalLoadable,
    exact: false,
  },
  {
    path: ProfileCountriesVisitedEditPath,
    component: ProfileCountriesVisitedEditLoadable,
    exact: false,
  },
  {
    path: vipMegafonPath,
    component: VipMegafonLoadable,
    exact: false,
  },
  {
    path: vipBeelineSubscriptionPath,
    component: VipBeelineLoadable,
    exact: false,
  },
  {
    path: restrictedPhotoWarningFullPath,
    component: RestrictedPhotoWarningModalLoadable,
    exact: false,
  },
  {
    path: requestGeoPermissionPath,
    component: GeoPermissionRequestModal,
    authorizedOnly: true,
  },
  {
    path: diamondsPath,
    component: DiamondsModalLoadable,
  },
  ...locationRoutes,
  ...teamoModalRoutes,
  ...astroStarModalRoutes,
]

export default commonModalRoutes
