import React from 'react'

import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'

import { hideTooltipsAction } from 'actions/tooltipAction'
import { Colors } from 'common-constants/colors'
import { isModifiedEvent } from 'functions/client'
import { leftClickButton } from 'functions/leftClickButton'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { push, replace } from 'functions/router'
import { useLayoutContext } from 'hooks/useLayoutContext'

/**
 * @deprecated Colors.link
 * @type {string}
 */
export const linkColor = '#46aae9'

/**
 * @deprecated Colors.linkTransparent
 * @type {string}
 */
export const linkTransparentColor = '#f56323'
/**
 * @deprecated Colors.linkTransparentHover
 * @type {string}
 */
export const linkTransparentHoverColor = '#e75110'

// TODO: почему то ref не доезжает до customLink
/**
 *
 * @param {*} param0
 * replace - пока что только для модальных ссылок. Чтобы модалы перезаписывали друг друга
 * @returns
 */
export const CustomLink = ({
  target,
  onClick,
  to,
  scrollToTop,
  ref,
  className,
  children,
  useStartEvent = false,
  isExternalLink = false,
  ['data-name']: dataName,
  ...rest
}) => {
  const dispatch = useDispatch()
  const handleClick = (event) => {
    if (
      !event.defaultPrevented && // onClick prevented default
      leftClickButton(event) && // ignore everything but left clicks
      (!target || target === '_self') && // let browser handle "target=_blank" etc.
      !isModifiedEvent(event) && // ignore clicks with modifier keys
      !isExternalLink // флаг для ссылки наружу.
    ) {
      event.preventDefault()
      event.stopPropagation()
      // fullStopPropagation(event)
      dispatch(hideTooltipsAction())

      if (onClick) {
        onClick(event)
      } else if (to) {
        if (rest.replace) {
          dispatch(replace(to, scrollToTop))
        } else {
          dispatch(push(to, scrollToTop))
        }
      }
    }
  }

  const properties = {
    onClick: handleClick,
    ref,
    className,
    target,
  }

  /** TODO разобраться, почему было такое кривое условие */
  if (to) {
    properties.href = to
  }

  if (useStartEvent) {
    delete properties.onClick
    properties.onMouseDown = handleClick
    properties.onTouchStart = handleClick
  }

  return (
    <a {...properties} data-name={dataName} rel={rest['rel']}>
      {children}
    </a>
  )
}

export const linkDefaultCss = css`
  text-decoration: none;
  color: ${(props) => (props.$linkColor ? props.$linkColor : Colors.link)};

  @media (hover: hover) {
    &:hover {
      color: ${(props) =>
        props.$linkHoverColor ? props.$linkHoverColor : Colors.linkHover};
    }
  }
`

export const RouterLink = styled(CustomLink)`  
  /* prettier-ignore */
  ${linkDefaultCss}
  ${(props) =>
    props.flex &&
    `
      display: flex;
      flex-direction: row;
      align-items: center;
  `};

  ${(props) => props.style}
  min-height: auto;
`

const routerLinkMailRuCss = css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  transition: all 0.1s linear;
  box-sizing: border-box;
  padding: 8px 9px;

  &:hover {
    background-color: ${(props) => props.backgroundHoverColor};
  }
`

export const ModalLink = ({ to, ...rest }) => {
  const layoutContext = useLayoutContext()
  return <RouterLink {...rest} to={mergeAllUrls(layoutContext.baseUrl, to)} />
}

export const PlainLink = styled(RouterLink).attrs({
  as: 'a',
})`
  cursor: pointer;
`

export const LinkColor = styled(RouterLink).attrs({
  as: 'span',
})`
  cursor: pointer;
  outline: 0;
`

export const LinkColorButton = styled(RouterLink).attrs({
  as: 'span',
})`
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
`

export const ActionLink = styled(RouterLink).attrs({
  as: 'button',
})`
  cursor: pointer;
  padding: 0;
  border-width: 0;
  font-size: inherit;
  background-color: white;
  -webkit-appearance: none;

  &[disabled] {
    color: ${Colors.noteColor};
    cursor: default;
    pointer-events: none;
  }
`

/**
 * Имитирует кнопку, т.к. у хрома происходит смещение текста на пиксель вниз
 * если использовать настоящую кнопку с выравниванием флексом
 */
export const ButtonLink = ({ children, ...rest }) => (
  <LinkColor {...rest} role="button" tabIndex="0">
    {children}
  </LinkColor>
)

export const PlainRouterLink = styled(CustomLink)`
  text-decoration: none;
  color: inherit;
`

export const MailRuRouterLink = styled(RouterLink)`
  ${routerLinkMailRuCss};
`

export const MailRuPlainLink = styled(PlainLink)`
  ${routerLinkMailRuCss};
`
export const ModalLinkThrough = ({ url, children, ...props }) => {
  const { baseUrl } = useLayoutContext()

  return (
    <RouterLink to={mergeAllUrls(baseUrl, url)} {...props}>
      {children}
    </RouterLink>
  )
}
