// eslint-disable-next-line import/order
import { enableSentry } from './instrument'

/** Без вызова функции вебпак выкидывает код из сборки */
if (process.env.SENTRY_ENABLED) {
  enableSentry()
}

import React from 'react'

import { loadableReady } from '@loadable/component'
import { setTag } from '@sentry/core'
import { createRoot, hydrateRoot } from 'react-dom/client'

import { authorizeBySecretAction } from 'actions/authorization/authorizeBySecretAction'
import { captchaLoadedAction } from 'actions/captchaAction'
import { installServiceWorkerAction } from 'actions/pwa/installServiceWorkerAction'
import { restorePartialStateAction } from 'actions/system/localStorageAction'
import { updateReferrerAction } from 'actions/system/systemAction'
import { updateCookiesAction } from 'actions/system/updateCookiesAction'
import { updateQueryAction } from 'actions/system/updateQueryAction'
import { secretParam, uidParam } from 'common/constantsAuthBySecret'
import { loadStorageState } from 'common/store/loadStorageState'
import { setUpYandexAds } from 'components/banner/yandex/setUpYandexAds'
import { layoutSettingsPath } from 'components/layout/MambaLayout/paths'
import { shellPath } from 'components/page/Pwa/Pwa.paths'
import {
  settingsVerificationPath,
  verifyBySocialLoadingPath,
} from 'components/page/Settings/Settings.paths'
import { createSearch } from 'functions/createSearch'
import { getAppElement } from 'functions/getAppElement'
import {
  findLocaleWithDefaultFallback,
  fixForReactIntl,
} from 'functions/locale'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { isPwaSupported } from 'functions/pwa/isPwaSupported'
import { updateShell } from 'functions/pwa/updateShell'
import { push, replace } from 'functions/router'

import ApplicationStart from './ApplicationStart'
import { checkSession } from './checkSession'
/**
 * Не удалять импорт history, Сентри не может подключится к переходам
 * страниц.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { historyFirstRedux, store, history } from './configureClientStore'
import { mockServiceWorker } from './functions/mockServiceWorker'
import { isInSearchEnginesCache } from './isInSearchEnginesCache'
import { polyfill } from './polyfills'

const { dispatch } = store
const { locale } = store.getState().systemReducer
export const internalLocale = findLocaleWithDefaultFallback(locale)

window.onloadCallback = () => dispatch(captchaLoadedAction())

const pwaSupported = isPwaSupported()
setTag('pwa', pwaSupported ? 'yes' : 'no')
setTag('country', store.getState().userReducer.countryId)
setTag('partner', store.getState().systemReducer.partnerId)
setTag(
  'authorized',
  store.getState().authorizationReducer.authorized ? 'yes' : 'no'
)

if (pwaSupported) {
  dispatch(updateQueryAction())
}

// mamba2/.templates/ru/default/verify_real_using_social_network/verify.tpl:19
window.onSocialVerifyCallback = ({ state, provider, code }) => {
  // dispatch(verifySocialWindowAction(params))
  dispatch(
    push(
      `${mergeAllUrls(
        layoutSettingsPath,
        settingsVerificationPath,
        verifyBySocialLoadingPath
      )}?state=${state}&provider=${provider}&code=${code}`
    )
  )
}

window.uniWeb = true
window.pushCallback = (path) => dispatch(push(path))
window.replaceCallback = (path) => dispatch(replace(path))
window.tryUpdateShell = updateShell

setUpYandexAds()

const waitLoadable = () =>
  new Promise<void>((resolve: () => void) => {
    loadableReady(resolve).catch(console.error)
  })

if (isInSearchEnginesCache()) {
  console.warn('Search engine cache')
} else {
  const startUniweb = async () => {
    const promises: Promise<unknown>[] = [
      waitLoadable(),
      checkSession(),
      polyfill(fixForReactIntl(internalLocale)),
    ]

    if (
      process.env.NODE_ENV === 'development' &&
      process.env.ENABLE_MOCK_SERVER === 'true'
    ) {
      promises.push(mockServiceWorker())
    }

    const search = createSearch()

    const uid = search.get(uidParam)
    const secret = search.get(secretParam)

    /**
     * Авторизуем на старте клиента, чтобы не допустить параллельных запросов,
     * которые получать ответ, что юзер не авторизован.
     **/
    if (uid && secret) {
      promises.push(dispatch(authorizeBySecretAction(uid, secret)) as any)
    }

    await Promise.all(promises)

    /**
     * Восстанавливаем локальную историю после рефреша.
     */
    const partialState = loadStorageState()

    if (partialState.locationsHistory) {
      store.dispatch(
        restorePartialStateAction(partialState, 'locationsHistory')
      )
    }

    if (
      window.__INITIAL_STATE__.router.location.pathname.startsWith(shellPath)
    ) {
      /**
       * Так как запроса к серверу не было, надо обновить реферрер на клиенте.
       */
      store.dispatch(updateReferrerAction(document.referrer))
      store.dispatch(updateCookiesAction())

      const root = createRoot(getAppElement())
      root.render(
        <ApplicationStart
          locale={fixForReactIntl(internalLocale)}
          history={historyFirstRedux}
        />
      )
    } else {
      hydrateRoot(
        getAppElement(),
        <ApplicationStart
          locale={fixForReactIntl(internalLocale)}
          history={historyFirstRedux}
        />,
        {
          onRecoverableError: (error, errorInfo) => {
            /** Так как не фатальное, оставляют так */
            console.warn('Hydrate recoverable error', error, errorInfo)
            // captureException(new Error('Hydrate root error'))
          },
        }
      )
    }
  }

  startUniweb().catch(console.error)
}

dispatch(installServiceWorkerAction())
